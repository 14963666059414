import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registered-teams',
  templateUrl: './registered-teams.component.html',
  styleUrls: ['./registered-teams.component.scss'],
})
export class RegisteredTeamsComponent implements OnInit {
  teams;
  filteredTeams = [];
  selectedLocation = 'all';
  scoreModePassword;

  locationOptions = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchTeams();
  }

  async fetchTeams() {
    return this.http.get<any>(environment.apiUrl + '/teams/results').subscribe(
      (data) => {
        this.teams = data.sort((a, b) => {
          return (
            a.location.localeCompare(b.location) || a.time.localeCompare(b.time)
          );
        });
        for (const t of this.teams) {
          if (this.locationOptions.indexOf(t.location) == -1) {
            this.locationOptions.push(t.location);
          }
        }
        this.filterTeams();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterTeams() {
    this.filteredTeams = this.teams.filter((a) => {
      return (
        this.selectedLocation == 'all' || this.selectedLocation == a.location
      );
    });
  }

  enableScoreMode() {
    this.scoreModePassword = window.localStorage.getItem('hiitPassword');
    if (!this.scoreModePassword) {
      this.scoreModePassword = prompt('Please enter the secret password...');
      window.localStorage.setItem('hiitPassword', this.scoreModePassword);
    }

    this.http
      .get<any>(environment.apiUrl + '/teams/all', {
        headers: {
          Authorization: this.scoreModePassword,
        },
      })
      .subscribe(
        (data) => {
          this.teams = data.sort((a, b) => {
            return (
              a.location.localeCompare(b.location) ||
              a.time.localeCompare(b.time)
            );
          });
          this.filterTeams();
          window.localStorage.setItem('hiitPassword', this.scoreModePassword);
        },
        (err) => {
          alert('Incorrect Password!');
          window.localStorage.removeItem('hiitPassword');
          this.scoreModePassword = null;
        }
      );
  }

  saveScore(t) {
    console.log(t);
    t.editScore = false;
    this.http
      .post<any>(
        environment.apiUrl + `/team/${t.PK}/score`,
        { score: t.score },
        {
          headers: {
            Authorization: this.scoreModePassword,
          },
        }
      )
      .subscribe(
        (data) => {
          console.log(data);
        },
        (err) => {
          console.log(err);
          alert(err);
        }
      );
  }

  async downloadTeamsCsv() {
    const password = prompt('Please enter the secret password...');
    this.http
      .get<any>(environment.apiUrl + '/teams/all', {
        headers: {
          Authorization: password,
        },
      })
      .subscribe(
        (data) => {
          if (!data) {
            alert('Password incorrect!');
            return;
          }

          const now = new Date();
          let fileName = `showdown-teams-${now.getDate()}-${
            now.getMonth() + 1
          }-${now.getFullYear()}.csv`;

          let csvData =
            'teamName,division,time,location,teamId,name,email,mobile,isMember,waiverAccepted,isCaptain,score\n';

          for (const t of data) {
            for (const m of t.members) {
              csvData += `"${t.name}","${t.division}","${t.time}","${
                t.location
              }","${t.shareCode}","${m.name}","${m.email}","${m.mobile}","${
                m.isHiitMember ? 'YES' : 'NO'
              }","${m.waiverAccepted ? 'YES' : 'NO'}","${
                m.mobile == t.captain.mobile && m.name == t.captain.name
                  ? 'YES'
                  : 'NO'
              }","${t.score ? t.score : 'NA'}"\n`;
            }
          }

          var pom = document.createElement('a');
          var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', fileName);
          pom.click();
        },
        (err) => {
          alert(err);
        }
      );
  }
}
