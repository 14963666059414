<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
  <form
    action="https://clublime.us14.list-manage.com/subscribe/post?u=cdb0d51e34bed25109892bea4&amp;id=65a20d8843"
    method="post"
    id="mc-embedded-subscribe-form"
    name="mc-embedded-subscribe-form"
    class="validate"
    target="_blank"
    novalidate
  >
    <div id="mc_embed_signup_scroll">
      <!-- <h2>Subscribe</h2> -->
      <!-- <div class="indicates-required">
        <span class="asterisk">*</span> indicates required
      </div> -->
      <div class="mc-field-group">
        <label for="mce-NAME">Full Name <span class="asterisk">*</span> </label>
        <input
          type="text"
          value=""
          name="NAME"
          minlength="1"
          class="required"
          id="mce-NAME"
        />
      </div>
      <div class="mc-field-group">
        <label for="mce-MOBILE"
          >Mobile Number <span class="asterisk">*</span>
        </label>
        <input
          type="text"
          value=""
          name="MOBILE"
          class="required"
          id="mce-MOBILE"
          pattern="^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$"
        />
      </div>
      <div class="mc-field-group">
        <label for="mce-EMAIL"
          >Email Address <span class="asterisk">*</span>
        </label>
        <input
          type="email"
          value=""
          name="EMAIL"
          class="required email"
          pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
          id="mce-EMAIL"
        />
      </div>
      <div id="mce-responses" class="clear">
        <div
          class="response"
          id="mce-error-response"
          style="display: none"
        ></div>
        <div
          class="response"
          id="mce-success-response"
          style="display: none"
        ></div>
      </div>
      <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      <div style="position: absolute; left: -5000px" aria-hidden="true">
        <input
          type="text"
          name="b_cdb0d51e34bed25109892bea4_65a20d8843"
          tabindex="-1"
          value=""
        />
      </div>
      <p class="mc-status"></p>
      <div id="form-button" class="clear">
        <input
          type="submit"
          value="Submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          class="button"
        />
      </div>
    </div>
  </form>
</div>

<!--End mc_embed_signup-->
