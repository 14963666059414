<div class="field-group">
  <label for="division">Division:</label>
  <select name="division" id="division" [(ngModel)]="team.division">
    <option *ngFor="let d of divisions" [value]="d">{{ d }} division</option>
  </select>
</div>
<div class="field-group">
  <label for="location">Location:</label>
  <select name="location" id="location" [(ngModel)]="team.location">
    <option *ngFor="let l of locations" [value]="l">
      {{ l }}
    </option>
  </select>
</div>
<div class="field-group">
  <label for="time">Time:</label>
  <select name="time" id="time" [(ngModel)]="team.time">
    <option *ngFor="let t of times" [value]="t">{{ t }}</option>
  </select>
</div>
