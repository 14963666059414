import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisteredTeamsComponent } from './pages/registered-teams/registered-teams.component';
import { TeamComponent } from './pages/team/team.component';
import { TermsPrivacyComponent } from './pages/terms-privacy/terms-privacy.component';

const routes: Routes = [
  { path: '', redirectTo: '/register', pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'privacy', component: TermsPrivacyComponent },
  { path: 'registered-teams', component: RegisteredTeamsComponent },
  { path: 'team/:teamCode', component: TeamComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
