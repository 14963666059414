<div class="bg-fixed"></div>

<div class="page">
  <div #topcontainer class="top-container">
    <div class="logo">
      <img class="desktop-logo" src="/assets/img/showdown-2023-logo.png" />
      <img class="mobile-logo" src="/assets/img/showdown-2023-logo.png" />
    </div>

    <img class="leaderboard-title" src="/assets/img/leaderboard-title.png" />

    <p>
      Congratulations on getting through the Showdown, check out your results
      below.
    </p>

    <div class="filters">
      <div class="field-group">
        <label for="division">Division</label>
        <select
          name="division"
          id="division"
          [(ngModel)]="filter.division"
          (change)="filterTeams()"
        >
          <option value="overall">OVERALL</option>
          <option *ngFor="let d of divisions">
            {{ d }}
          </option>
        </select>
      </div>
      <div class="field-group">
        <label for="location">Location</label>
        <select
          (change)="filterTeams()"
          name="location"
          id="location"
          [(ngModel)]="filter.selectedLocation"
        >
          <option value="all">ALL</option>
          <option *ngFor="let l of locations">
            {{ l }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="table-section">
    <table>
      <thead>
        <tr>
          <th class="text-center">Rank</th>
          <th>Team Name</th>
          <th>Score</th>
          <th>Division</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let res of results; let i = index">
          <td class="text-center">{{ i + 1 }}</td>
          <td>{{ res.name }}</td>
          <td>{{ res.score }}</td>
          <td>{{ res.division }}</td>
          <td>{{ res.location }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
