<div class="container">
  <div class="logo">
    <img class="desktop-logo" src="/assets/img/showdown-2023-logo.png" />
    <img class="mobile-logo" src="/assets/img/showdown-2023-logo.png" />
  </div>
  <h1>Registered Teams</h1>

  <div class="button" (click)="downloadTeamsCsv()">Download CSV</div>
  <div class="button" *ngIf="!scoreModePassword" (click)="enableScoreMode()">
    Enable Score Mode
  </div>
  <select [(ngModel)]="selectedLocation" (change)="filterTeams()">
    <option value="all">ALL Locations</option>
    <option *ngFor="let l of locationOptions">{{ l }}</option>
  </select>

  <h2>Total teams: {{ teams.length }}</h2>

  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th class="hidden-mobile">Division</th>
        <th class="hidden-mobile">Location</th>
        <th class="hidden-mobile">Time</th>
        <th>Score</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of filteredTeams">
        <td>{{ t.name }}</td>
        <td class="hidden-mobile">{{ t.division }}</td>
        <td class="hidden-mobile">{{ t.location }}</td>
        <td class="hidden-mobile">{{ t.time }}</td>
        <td>
          <span *ngIf="!t.editScore">{{ t.score || "Not set" }}</span>
          <input *ngIf="t.editScore" type="number" [(ngModel)]="t.score" />
          <span
            class="edit-button"
            (click)="t.editScore = true"
            *ngIf="scoreModePassword && !t.editScore"
            >{{ t.score ? "Update" : "Enter Score" }}</span
          >
          <span class="edit-button" (click)="saveScore(t)" *ngIf="t.editScore"
            >Save</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
