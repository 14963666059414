<div class="bg-fixed"></div>

<div class="page" *ngIf="loaded">
  <div
    #topcontainer
    class="top-container"
    [style.opacity]="calculateBlur()"
    style.transform="translateY({{ calculateScale() }})"
  >
    <div class="logo">
      <img class="desktop-logo" src="/assets/img/showdown-2023-logo-nodate.png" />
      <img class="mobile-logo" src="/assets/img/showdown-2023-logo-nodate.png" />
    </div>

    <div class="hero-banner">
      <div class="banner-text">
        <img src="/assets/img/banner-text-2023-compile.png" />
      </div>
    </div>

    <p>
      Get ready to smash hiit at our biggest hiit team event. The Showdown is
      your chance to challenge yourself and see if you and your mates will be
      crowned the hiit republic Showdown champions!
    </p>
    <p>
      Get your crew together and register for this insane, high intensity event.
    </p>
    <p>
      <strong>One workout, one day, across multiple national locations.</strong>
    </p>
    <p>
      Register your team of 2-4 members by clicking the link below.
    </p>
    <a href="/register#register" class="button">Register</a>
  </div>
</div>
<div class="scrollable-section" [style.marginTop.px]="topContainerHeight">
  <div class="info-container" *ngIf="loaded">
    <img src="/assets/img/showdown-2023-heading1.png" class="section-heading" />
    <h3 class="fix-margin">Date:</h3>
    <p>Sat 25 Feb 2023</p>
    <h3>Session times:</h3>
    <p>7am, 8am, 9am</p>
    <br>
    <h3>Locations:</h3>
    <div class="locations">
      <p *ngFor="let l of locations" class="location">{{ l }}</p>
    </div>
    <br>
    <h3>Price:</h3>
    <p>FREE</p>
    <h3>Prizes:</h3>
    <p>
      Incredible overall division and studio prizes will be up for grabs on the
      day!
    </p>
    <h3>Registration close:</h3>
    <p>Fri 24 Feb 2023</p>
    <p><br /></p>
    <p>
      Participants must be at least 16 years old.<br />
      Please arrive 10 min prior to your event commencing<br />
      Members can invite non-members to attend in the event.
    </p>
  </div>

  <div class="success register-container" *ngIf="saved">
    <h2>Congratulations!!</h2>
    <h3>Your team is now registered!</h3>
    <p>
      We have emailed you a link to invite your friends to join your showdown
      team. Check your inbox (and spam folder).
    </p>
  </div>

  <div id="register" class="register-container" *ngIf="loaded && !saved">
    <img src="/assets/img/showdown-2023-heading2.png" class="section-heading" />
    <p>
      Register as a team captain for the Showdown (1 captain per team). Once registered,
      we will send you a unique link to share with your 2 teammates that will allow them to join.
    </p>
    <br/>
    <form class="form-container">
      <div class="field-group">
        <label for="team-name">Team Name</label>
        <input
          [(ngModel)]="team.name"
          type="text"
          value=""
          name="team-name"
          class="cursive"
          id="team-name"
          maxlength="20"
        />
      </div>

      <h3>Event Info</h3>
      <app-event-info [team]="team"></app-event-info>

      <h3>Your Personal Info</h3>
      <app-member-signup [member]="team.captain"></app-member-signup>

      <div class="form-error-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <app-ellipsis *ngIf="saving"></app-ellipsis>

      <app-button
        backgroundColor="#ff6900"
        (click)="saveTeam($event)"
        *ngIf="
          (!saving && !saved && team.captain.isHiitMember) ||
          (!saving && !team.captain.isHiitMember && team.captain.waiverAccepted)
        "
        >Register my team</app-button
      >
    </form>
  </div>
</div>

<app-loading [hidden]="loaded"></app-loading>
