import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  loaded = false;
  scrollHeight = 0;
  topContainerHeight = 1080;

  showJoinTeam = false;

  errorMessage: string;
  saving = false;

  teamNotFound = false;
  vacantSpots = [];

  teamCode;
  team;

  newTeamMember = {
    name: '',
    mobile: '',
    email: '',
    isHiitMember: true,
    waiverAccepted: false,
  };

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.teamCode = params['teamCode'];
      this.fetchTeam();
    });

    if (window.localStorage.getItem('newTeam')) {
      let newTeam = JSON.parse(window.localStorage.getItem('newTeam'));
      window.localStorage.removeItem('newTeam');
      console.log(newTeam);
    }
  }

  async fetchTeam() {
    return this.http
      .get<any>(environment.apiUrl + '/team/' + this.teamCode)
      .subscribe(
        (data) => {
          this.team = data;
          this.vacantSpots = Array(4 - this.team.members.length)
            .fill(0)
            .map((x, i) => i);

          console.log(this.vacantSpots);
        },
        (err) => {
          this.teamNotFound = true;
        }
      );
  }

  dropMember(member) {
    const response = confirm(
      'Are you sure you want to remove ' + member.name + ' from the team?'
    );

    if (!response) return;

    const index = this.team.members.indexOf(member);
    this.team.members.splice(index, 1);
    this.vacantSpots = Array(4 - this.team.members.length)
      .fill(0)
      .map((x, i) => i);
    this.http
      .post(environment.apiUrl + `/team/${this.teamCode}/drop-member`, member)
      .subscribe(() => {});
  }

  joinNewMember(e) {
    e.preventDefault();

    this.errorMessage = null;

    this.newTeamMember.mobile = this.newTeamMember.mobile.replace(/ /g, '');

    if (!this.newTeamMember.name) {
      this.errorMessage = 'Please enter your name';
      return;
    }

    if (!this.newTeamMember.mobile) {
      this.errorMessage = 'Please enter your mobile';
      return;
    }

    if (!this.validateMobile(this.newTeamMember.mobile)) {
      this.errorMessage =
        'Mobile number should start with 04 and only contain numbers';
      return;
    }

    if (
      !this.newTeamMember.email ||
      !this.validateEmail(this.newTeamMember.email)
    ) {
      this.errorMessage = 'Please check your email';
      return;
    }

    console.log(this.newTeamMember);
    this.saving = true;

    this.http
      .post(
        environment.apiUrl + `/team/${this.teamCode}/join`,
        this.newTeamMember
      )
      .subscribe(() => {
        this.saving = false;
        this.team.members.push(this.newTeamMember);
        this.vacantSpots = Array(4 - this.team.members.length)
          .fill(0)
          .map((x, i) => i);

        this.showJoinTeam = false;
        this.newTeamMember = {
          name: '',
          mobile: '',
          email: '',
          isHiitMember: true,
          waiverAccepted: false,
        };
      });
  }

  onInfoChange() {
    const updatedOptions = {
      division: this.team.division,
      time: this.team.time,
      location: this.team.location,
    };

    this.http
      .post(
        environment.apiUrl + `/team/${this.teamCode}/update-info`,
        updatedOptions
      )
      .subscribe(() => {
        console.log('Info updated');
      });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateMobile(mobile: string) {
    if (!mobile.startsWith('04')) return false;
    return mobile.match(/^[0-9]+$/) != null;
  }
}
