import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent implements OnInit {
  @Input() team;

  // Also in "register" page
  locations = [
    'Albury',
    'Braddon',
    'Ballarat',
    'Campbelltown',
    'Coburg',
    'Fyshwick',
    'Goulburn',
    'Mitchell',
    'Tuggeranong',
    'Wagga Wagga',
    'Wollongong',
    'Yamanto',
  ];

  divisions = ['mixed', 'female', 'male'];
  times = ['7am', '8am', '9am'];

  constructor() {}

  ngOnInit(): void {}
}
