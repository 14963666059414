<div class="page">
  <div class="logo">
    <img class="desktop-logo" src="/assets/img/showdown-2023-logo.png" />
    <img class="mobile-logo" src="/assets/img/showdown-2023-logo.png" />
  </div>

  <div class="hero-banner" *ngIf="!teamNotFound">
    <div class="images">
      <img src="/assets/img/banner-image2.png" />
      <img src="/assets/img/banner-image3.png" />
    </div>
    <div class="banner-text">
      <img src="/assets/img/invited-header.svg" />
    </div>
  </div>

  <h1 *ngIf="teamNotFound">Team not found</h1>

  <app-ellipsis *ngIf="!team && !teamNotFound"></app-ellipsis>

  <div class="form-container" *ngIf="team">
    <div class="team-name">
      <label>Team Name:</label>
      <div class="team-name-text">
        {{ team.name }}
      </div>
    </div>

    <h3>Event Info</h3>
    <app-event-info
      *ngIf="team"
      [team]="team"
      (change)="onInfoChange()"
    ></app-event-info>

    <h3>Team Members</h3>
    <h4 class="teams-size-message">Teams must have 2 - 4 members</h4>
    <div class="team-members" *ngIf="team">
      <div class="member-row" *ngFor="let t of team.members">
        <div class="member-name">{{ t.name }}</div>
        <div
          class="close"
          (click)="dropMember(t)"
          *ngIf="team.members.length > 1"
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="94.926px"
            height="94.926px"
            viewBox="0 0 94.926 94.926"
            style="enable-background: new 0 0 94.926 94.926"
            xml:space="preserve"
          >
            <g>
              <path
                d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0
              c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096
              c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476
              c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62
              s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="member-row vacant" *ngFor="let t of vacantSpots">
        <div class="member-name">
          Team member {{ team.members.length + 1 + t }}
        </div>
      </div>
      <div class="member-row team-full" *ngIf="team.members.length >= 4">
        <div class="member-name">Your team is complete</div>
      </div>
    </div>

    <app-button
      *ngIf="!showJoinTeam && team.members.length < 4"
      (click)="showJoinTeam = true"
      backgroundColor="#ff6900"
    >
      Join this team
    </app-button>

    <h3 *ngIf="showJoinTeam">New team member registration form</h3>
    <div *ngIf="showJoinTeam" class="rego-form">
      <app-member-signup [member]="newTeamMember"></app-member-signup>

      <div class="form-error-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <app-ellipsis *ngIf="saving"></app-ellipsis>

      <app-button
        *ngIf="
          (!saving && newTeamMember.isHiitMember) ||
          (!saving &&
            !newTeamMember.isHiitMember &&
            newTeamMember.waiverAccepted)
        "
        (click)="joinNewMember($event)"
        >Join</app-button
      >
    </div>
  </div>
</div>
