import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit {
  locations = [];
  divisions = [];

  filter = {
    division: 'overall',
    selectedLocation: 'all',
  };

  results = [];
  teams = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchTeams();
    setInterval(() => {
      this.fetchTeams();
    }, 30000);
  }

  async fetchTeams() {
    return this.http.get<any>(environment.apiUrl + '/teams/results').subscribe(
      (data) => {
        this.teams = data.sort((a, b) => {
          return (
            a.location.localeCompare(b.location) || a.time.localeCompare(b.time)
          );
        });
        for (const t of this.teams) {
          if (this.locations.indexOf(t.location) == -1) {
            this.locations.push(t.location);
          }
          if (this.divisions.indexOf(t.division) == -1) {
            this.divisions.push(t.division);
          }
        }
        this.filterTeams();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterTeams() {
    this.results = this.teams
      .filter((a) => {
        return (
          a.score &&
          (this.filter.division == 'overall' ||
            this.filter.division == a.division) &&
          (this.filter.selectedLocation == 'all' ||
            this.filter.selectedLocation == a.location)
        );
      })
      .sort((a, b) => {
        return b.score - a.score;
      });
  }
}
