import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-member-signup',
  templateUrl: './member-signup.component.html',
  styleUrls: ['./member-signup.component.scss'],
})
export class MemberSignupComponent implements OnInit {
  @Input() member: any;

  constructor() {}

  ngOnInit(): void {}
}
