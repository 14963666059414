<div class="field-group">
  <label for="captain-name">Full Name:</label>
  <input
    [(ngModel)]="member.name"
    type="text"
    value=""
    name="captain-name"
    class="required"
    id="captain-name"
  />
</div>
<div class="field-group">
  <label for="captain-mobile">Mobile:</label>
  <input
    [(ngModel)]="member.mobile"
    type="text"
    value=""
    name="captain-mobile"
    maxlength="15"
    class="required"
    id="captain-mobile"
  />
</div>
<div class="field-group">
  <label for="captain-email">Email Address:</label>
  <input
    [(ngModel)]="member.email"
    type="text"
    value=""
    name="captain-email"
    class="required"
    id="captain-email"
  />
</div>
<div class="field-group">
  <label>Member Status:</label>
  <div class="checkboxes">
    <div class="checkbox">
      <app-checkbox
        [checked]="member.isHiitMember"
        (click)="member.isHiitMember = !member.isHiitMember"
      ></app-checkbox>
      <label (click)="member.isHiitMember = !member.isHiitMember"
        >A member!</label
      >
    </div>
    <div class="checkbox">
      <app-checkbox
        [checked]="!member.isHiitMember"
        (click)="member.isHiitMember = !member.isHiitMember"
      ></app-checkbox
      ><label (click)="member.isHiitMember = !member.isHiitMember"
        >Not a member</label
      >
    </div>
  </div>
</div>
<div class="privacy-accept" *ngIf="!member.isHiitMember">
  <div class="left-side">
    <app-checkbox
      [checked]="member.waiverAccepted"
      (click)="member.waiverAccepted = !member.waiverAccepted"
    ></app-checkbox>
  </div>
  <div class="right-side">
    <p>
      I have read, understood and agree to the hiit republic
      <a href="/privacy" target="_blank">Privacy Policy and Terms of Use</a>.
    </p>
  </div>
</div>
