import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  loaded = false;
  scrollHeight = 0;
  topContainerHeight = 1080;

  errorMessage: string;

  saving = false;
  saved = false;

  // Also in "event-info" page
  locations = [
    'Albury',
    'Braddon',
    'Ballarat',
    'Campbelltown',
    'Coburg',
    'Fyshwick',
    'Goulburn',
    'Mitchell',
    'Tuggeranong',
    'Wagga Wagga',
    'Wollongong',
    'Yamanto',
  ];

  team = {
    name: '',
    captain: {
      name: '',
      mobile: '',
      email: '',
      isHiitMember: true,
      waiverAccepted: false,
    },
    members: [],
    division: null,
    location: null,
    time: null,
  };

  constructor(private http: HttpClient, private router: Router) {}

  @ViewChild('topcontainer') topContainer: ElementRef;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.scrollHeight = event.target.scrollingElement.scrollTop;
  }

  calculateBlur() {
    if (this.topContainerHeight > window.innerHeight) {
      if (this.topContainerHeight - window.innerHeight > this.scrollHeight)
        return 1;

      const totalScrolled = -(this.topContainerHeight - window.innerHeight);
      return 1 - (this.scrollHeight + totalScrolled) * 0.002;
    }
    return 1 - this.scrollHeight * 0.001;
  }

  calculateScale() {
    if (this.topContainerHeight > window.innerHeight) {
      if (this.topContainerHeight - window.innerHeight > this.scrollHeight)
        return -this.scrollHeight + 'px';

      const totalScrolled = -(this.topContainerHeight - window.innerHeight);
      return totalScrolled + (this.scrollHeight + totalScrolled) * -0.4 + 'px';
    }
    return this.scrollHeight * -0.4 + 'px';
  }

  saveTeam(e) {
    e.preventDefault();

    this.saving = false;
    this.saved = false;
    this.errorMessage = null;

    this.team.captain.mobile = this.team.captain.mobile.replace(/ /g, '');

    // Timeslot block list
    // if (this.team.time == "7am" && this.team.location == "Braddon") {
    //   this.errorMessage = "This timeslot and location is full. Please select another time.";
    //   return;
    // }

    if (!this.team.name) {
      this.errorMessage = 'Please choose a team name';
      return;
    }

    if (
      !this.team.name ||
      this.team.name.toLowerCase().includes('fuck') ||
      this.team.name.toLowerCase().includes('fuk') ||
      this.team.name.toLowerCase().includes('cock') ||
      this.team.name.toLowerCase().includes('shit') ||
      this.team.name.toLowerCase().includes('$hit') ||
      this.team.name.toLowerCase().includes('slut') ||
      this.team.name.toLowerCase().includes('cunt') ||
      this.team.name.toLowerCase().includes('kunt') ||
      this.team.name.toLowerCase().includes('nigg') ||
      this.team.name.toLowerCase().includes('whore') ||
      this.team.name.toLowerCase().includes('bitch')
    ) {
      this.errorMessage = 'Rude!! Please choose an appropriate team name';
      return;
    }

    if (!this.team.division) {
      this.errorMessage = 'Please choose a division';
      return;
    }

    if (!this.team.location) {
      this.errorMessage = 'Please choose a location';
      return;
    }

    if (!this.team.time) {
      this.errorMessage = 'Please choose a time';
      return;
    }

    if (!this.team.captain.name) {
      this.errorMessage = 'Please enter your name';
      return;
    }

    if (!this.team.captain.mobile) {
      this.errorMessage = 'Please enter your mobile';
      return;
    }

    if (
      !this.team.captain.email ||
      !this.validateEmail(this.team.captain.email)
    ) {
      this.errorMessage = 'Please check your email';
      return;
    }

    if (!this.validateMobile(this.team.captain.mobile)) {
      this.errorMessage =
        'Mobile number should start with 04 and only contain numbers';
      return;
    }

    this.team.members = [this.team.captain];

    this.saving = true;
    this.http.put(environment.apiUrl + '/team', this.team).subscribe(
      (data: any) => {
        console.log(data);

        this.saving = false;

        if (!data.success) {
          this.errorMessage = 'An unknown error occured.';
          this.saved = false;
        } else {
          this.saved = true;
        }
      },
      (error) => {
        this.saving = false;
        this.errorMessage = error.error;
      }
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaded = true;
      setTimeout(() => {
        this.topContainerHeight =
          this.topContainer.nativeElement.offsetHeight + 325;
      }, 100);
    }, 1500);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateMobile(mobile) {
    if (!mobile.startsWith('04')) return false;
    return mobile.match(/^[0-9]+$/) != null;
  }
}
