import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FormsModule } from '@angular/forms';
import { RegisterComponent } from './pages/register/register.component';
import { TeamComponent } from './pages/team/team.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { HttpClientModule } from '@angular/common/http';
import { EventInfoComponent } from './components/event-info/event-info.component';
import { MemberSignupComponent } from './components/member-signup/member-signup.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ButtonComponent } from './components/button/button.component';
import { EllipsisComponent } from './components/ellipsis/ellipsis.component';
import { TermsPrivacyComponent } from './pages/terms-privacy/terms-privacy.component';
import { RegisteredTeamsComponent } from './pages/registered-teams/registered-teams.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupFormComponent,
    LoadingComponent,
    RegisterComponent,
    TeamComponent,
    LeaderboardComponent,
    EventInfoComponent,
    MemberSignupComponent,
    CheckboxComponent,
    ButtonComponent,
    EllipsisComponent,
    TermsPrivacyComponent,
    RegisteredTeamsComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
