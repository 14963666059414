<div class="container">
  <div class="logo">
    <img class="desktop-logo" src="/assets/img/showdown-2023-logo.png" />
    <img class="mobile-logo" src="/assets/img/showdown-2023-logo.png" />
  </div>
  <h1>Privacy Policy and Terms of Use</h1>
  <p>
    In consideration of hiit republic and its associated entities (the
    Providers) agreeing to grant access to the Premises, I hereby undertake to
    indemnify the Providers against all claims, losses, suits and damages made
    against or suffered by the Providers, by reason of my decision to enter the
    Premises and use any facilities made available by the Providers.
  </p>

  <p>
    I understand that participation in fitness related activities can give rise
    to significant risks which include the risk of serious injury and/or death.
    In entering and utilizing the Providers’ facilities and the Premises I
    warrant that I do so voluntarily and that I assume all risks associated with
    my entry and use of the Providers’ facilities and the Premises. I further
    warrant that I have read and am aware of the Providers’ Terms of Entry and
    agree to adhere to those terms.
  </p>

  <p>
    I, for myself and on behalf of my heirs, assigns, personal representatives
    and next of kin, hereby release and hold harmless the Providers and agree
    not to make any claims with respect to any and all injury, disability,
    death, or loss or damage to person or property, whether caused by the
    negligence of the Providers or otherwise.
  </p>

  <p>
    I acknowledge and agree that representatives of the Providers may, from time
    to time, take photographs of activities within the Premises which may
    include me and that my image may be used in any social media marketing or in
    any other way the Providers deem appropriate. In the event that I do not
    wish for my image to be used by the Providers, I understand that I am
    required to provide notice in writing by emailing the Providers at
    info@vivaleisure.com.au
  </p>

  <p>
    I warrant that I have had sufficient opportunity to read this release of
    liability.
  </p>

  <p>
    I warrant that I fully understand its terms, understand that I have given up
    substantial rights by signing it, and sign it freely and voluntarily without
    inducement of any kind.
  </p>
  <p>
    I am aware that hiit republic undertakes various marketing activities from
    time-to-time and I provide my informed consent for hiit republic to use my
    personal information, as disclosed by me below, for any marketing purposes
    it wishes to undertake.
  </p>
</div>
